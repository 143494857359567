import "../components/templates/ProjectPost.scss";
import "react-image-lightbox/style.css";

import { Col, Row } from "react-styled-flexboxgrid";
import React, { useEffect, useState } from "react";

import BreadCrumb from "../components/partials/BreadCrumb/BreadCrumb.js";
import ChevronIcon from "../img/decoration/chevron-left.svg";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import Lightbox from "react-image-lightbox";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ProjectCard from "../components/partials/ProjectCard/ProjectCard.js";
import SectionTitle from "../components/partials/SectionTitle/SectionTitle.js";
import { StyledGrid } from "../helpers/helpers.js";
import { graphql } from "gatsby";
import { kebabCase } from "lodash";
import { useWindowSize } from "../helpers/helpers.js";

export const ProjectPostTemplate = ({
  helmet,
  title,
  description,
  featuredImage,
  projectImages,
  types,
  name,
  date,
  client,
  nextPost,
}) => {
  const { isMobile } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const lightBoxCompatibleArray = projectImages.map((image) =>
      !!image?.childImageSharp?.fluid?.originalImg
        ? image.childImageSharp.fluid.originalImg
        : false
    );
    setImages(lightBoxCompatibleArray);
  }, [projectImages]);

  return (
    <StyledGrid className="ProjectPost">
      {helmet || ""}
      {isOpen && (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % images.length)
          }
        />
      )}
      <Row>
        {!isMobile && (
          <Col xs={12}>
            <BreadCrumb
              sectionText={"Projets"}
              sectionUrl={"/projects/"}
              tagUrl={!!types && !!types.length ? `/projects/${kebabCase(types[0])}/` : ""}
              tagText={!!types && !!types.length ? types[0] : ""}
            />
          </Col>
        )}
        <Col xs={12} md={8} mdOffset={2}>
          <div className="header">
            <div
              className="go-back"
              role="button"
              onClick={() =>
                window !== "undefined" ? window.history.back() : false
              }
              tabIndex={0}
              onKeyDown={() =>
                window !== "undefined" ? window.history.back() : false
              }
              aria-label={"Revenir page precedente"}
            >
              <img src={ChevronIcon} alt="" />
            </div>
            <h1 className="title">{name}</h1>
            <div className="image-container">
              <PreviewCompatibleImage
                imageInfo={featuredImage}
                className="image"
              />
            </div>
          </div>
        </Col>
        <Col xs={12} md={9} mdOffset={1} className="info-bar">
          <div className="info">
            <p>Typologie</p>
            <h3>{!!types && !!types.length ? types[0] : "Indéfini"}</h3>
          </div>
          <div className="info">
            <p>Client</p>
            <h3>{client}</h3>
          </div>
          <div className="info">
            <p>Date</p>
            <h3>{new Date(date).getFullYear()}</h3>
          </div>
        </Col>
        <Col xs={12} md={6} mdOffset={1}>
          <p className="description">{description}</p>
        </Col>

        {projectImages.map((image, i) => {
          return (
            <Col
              xs={12}
              md={8}
              mdOffset={i % 2 === 0 ? 3 : 1}
              key={i}
              className="project-image"
              onClick={() => {
                setImageIndex(i);
                setIsOpen(true);
              }}
            >
              <PreviewCompatibleImage
                imageInfo={image}
                className="project-image"
              />
            </Col>
          );
        })}

        {!!nextPost && (
          <>
            <SectionTitle text="Prochain projet :" highlight="Projet" />
            <Col xs={12} md={10} mdOffset={1}>
              <ProjectCard
                {...nextPost.frontmatter}
                {...nextPost.fields}
                isHorizontal={true}
              />
            </Col>
          </>
        )}
      </Row>
    </StyledGrid>
  );
};

const ProjectPost = ({ data }) => {
  const post = data.post;
  const nextPost = data.nextPost ? data.nextPost : null;

  return (
    <Layout>
      <ProjectPostTemplate
        helmet={
          <Helmet titleTemplate="%s | Project">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        featuredImage={post.frontmatter.featuredImage}
        description={post.frontmatter.description}
        name={post.frontmatter.name}
        client={post.frontmatter.client}
        date={post.frontmatter.date}
        types={post.frontmatter.types}
        projectImages={post.frontmatter.projectImages}
        nextPost={nextPost}
      />
    </Layout>
  );
};

export default ProjectPost;

export const pageQuery = graphql`
  query ProjectPostByID($id: String!, $nextId: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        description
        title
        projects
        name
        date
        client
        types
        projectImages {
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              originalImg
            }
          }
        }
        featuredImage {
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              originalImg
            }
          }
        }
      }
    }
    nextPost: markdownRemark(id: { eq: $nextId }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        name
        date
        client
        types
        featuredImage {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;
